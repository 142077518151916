// Classes
import { RutTools } from '@/Classes/Static/RutTools'

// Component's Classes
import { InputButton } from '@/Components/Global/DataForm/Classes/InputButton'
import { InputText }   from '@/Components/Global/DataForm/Classes/InputText'
import { Select }      from '@/Components/Global/DataForm/Classes/Select'

// Constants
import { Component } from '@/Constants/Global/Component'

// Input's Validator
const rutStateValidator = (self: any) => {
	if (self.value === '') return null
	return RutTools.validator(self.value)
}

// Input's Validator
const rutKeyUpEvent = (self: any) => {
	if (self.value === '') return
	self.value = RutTools.formatter(self.value, '')
}

// Form Export
export default <any> {
	Companies: {
		params: {
			currentTab: 'companies',
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: 'companies',
					text: { onInsert: 'Nuevo Cliente', onUpdate: 'Actualizar Cliente' }
				},
				columns: [
					new InputText('code', 'Código').setReadOnly(Component.Actions.UPDATE),
					new Select('region', 'Región'),
					new InputText('rut', 'Rut').addEvent('keyup', rutKeyUpEvent).setPlaceHolder('Ej: 99888777-6').setReadOnly(Component.Actions.UPDATE).setState(rutStateValidator),
					new Select('commune', 'Comuna').setPlaceHolder('- Seleccione una Región -'),
					new InputText('name', 'Razón Social'),
					new Select('isClient', '¿Es Cliente?', ['Sí', 'No']),
					new InputText('address', 'Dirección de Facturación'),
					new Select('isValid', '¿Esta Activa?', ['Sí', 'No']),
					new Select('type', 'Tipo', ['Cliente', 'Proveedor', 'Sucursal Interna']),
				]
			}
		]
	},
	Storages: {
		params: {
			currentTab: 'storages',
			numColumns: 2,
		},
		tabs: [
			{
				header: {
					key: 'storages',
					text: { onInsert: 'Nueva Ubicación', onUpdate: 'Actualizar Ubicación' }
				},
				columns: [
					new InputText('code', 'Código').setReadOnly(Component.Actions.UPDATE),
					new Select('region', 'Región'),
					new InputText('codeLocation', 'Código Planta (SAP)').setReadOnly(Component.Actions.UPDATE),
					new Select('commune', 'Comuna').setPlaceHolder('- Seleccione una Región -'),
					new InputText('name', 'Nombre'),
					new InputButton('company', 'Cliente').setIcon('pen').setReadOnly(Component.Actions.UPDATE),
					new InputText('address', 'Dirección'),
					new InputButton('technical', 'Técnico').setIcon('pen'),
					new Select('isValid', '¿Esta Activa?', ['Sí', 'No']),
					new InputButton('userAdmin', 'Adm. Contrato').setIcon('pen')
				]
			}
		]
	}
}